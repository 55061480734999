<script>
  import Summary from "./Summary.svelte";
  export let tikers;
  export let pre_rank = {};

  let items;
  // let offset;

  $: fetch(`https://amekabutrak.com/json/current-count-tiker.json`)
    .then((r) => r.json())
    .then((data) => {
      console.log("data---------", data);
      items = data;
      tikers = data.length;
      window.scrollTo(0, 0);
    });

  let suretai;
  let sure_url;

  $: fetch(`https://amekabutrak.com/json/current.json`)
    .then((r) => r.json())
    .then((data) => {
      // console.log("data---------", data);
      suretai = data[1];
      sure_url =
        "http://egg.5ch.net/test/read.cgi/stock/" + data[0].substr(0, 10) + "/";
    });

  let pre_suretai;
  let pre_sure_url;
  let pre_sure_json_url;
  let pre_sure_unixtime;
  let pre_sure_month;
  let pre_sure_year;
  // let pre_rank = {};
  let pre_dat_ary;

  $: fetch(`https://amekabutrak.com/json/pre_current.json`)
    .then((r) => r.json())
    .then((pre_data) => {
      console.log("pre_data1---------", pre_data);
      pre_suretai = pre_data[1];
      pre_sure_url =
        "http://egg.5ch.net/test/read.cgi/stock/" +
        pre_data[0].substr(0, 10) +
        "/";

      console.log("pre_data2------", pre_data);

      pre_sure_unixtime = pre_data[0].substr(0, 10);
      let dateTime = new Date(pre_sure_unixtime * 1000);
      pre_sure_month = ("0"+ (dateTime.getMonth()+1)).substr(-2);

      console.log("pre_sure_month/",pre_sure_month);
      pre_sure_year = dateTime.getFullYear();
      pre_sure_json_url =
        "https://amekabutrak.com/json/" +
        pre_sure_year +
        "/" +
        pre_sure_month +
        "/" +
        pre_sure_unixtime +
        ".json";

      console.log("pre_sure_json_url---", pre_sure_json_url);
    });

  $: fetch(pre_sure_json_url)
    .then((r) => r.json())
    .then((pre_dat) => {
      console.log("pre_dat---------", pre_dat);
      pre_dat_ary = pre_dat;
      for (let i = 0; i < pre_dat.length; i++) {
        let pre_dat_line = pre_dat[i];
        // console.log("pre_dat_line----",pre_dat_line);
        pre_rank[pre_dat_line["name"]] = pre_dat_line["num"];
        // console.log("pre_rank----",pre_rank);
      }
      console.log("pre_rank----", pre_rank);
    });
</script>

<div class="contents-main">
  <div class="setumei mB30px">
    AmekabuTrak scans and identifies trending stocks on <a href={sure_url}
      >5ch thread</a
    > by ticker usage
  </div>
  <div class="tCr">
    (Current thread:<a href={sure_url} target="_blank">{suretai}</a>)
  </div>
  <div class="tCr">
    (Previous thread:<a href={pre_sure_url} target="_blank">{pre_suretai}</a>)
  </div>

  <div class="tCr">
    <a href="/history.html">history</a>
  </div>


  <article class="mT30px">
    <div class="flexbox ">
      <div class="rank header">Rank</div>
      <div class="ticker header">Ticker<br /> ({tikers}pcs.)</div>
      <div class="times header">Times</div>
      <div class="prerank header">Pre Rank</div>
      <div class="link header">Link</div>
    </div>
    {#if items}
      {#each items as item, i}
        <Summary {item} {i} {pre_rank} />
      {/each}
    {:else}
      <p class="loading">loading...</p>
    {/if}
  </article>
</div>

<style>
  .tAl {
    text-align: left;
  }
  .tAr {
    text-align: right;
  }
  .tCr {
    text-align: center;
  }
  .header {
    border-bottom: double;
  }

  .contents-main {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  article {
    margin-top: 30px;
  }
  .loading {
    opacity: 0;
    animation: 0.4s 0.8s forwards fade-in;
  }

  .mT30px {
    margin-top: 30px;
  }
  .flexbox {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }

  .rank {
    width: 100px;
    text-align: center;
  }

  .ticker {
    width: 100px;
    text-align: center;
  }

  .times {
    width: 100px;
    text-align: center;
  }

  .prerank {
    width: 100px;
    text-align: center;
  }

  .link {
    width: 100px;
    text-align: center;
  }

  .setumei {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
    text-align: center;
    font-family: "Segoe UI", sans-serif;
  }
</style>
