<script>
  export let item;
  export let i;
  export let pre_rank;

  // let pre = pre_rank[item.name];
  let pre;

  function updown(item, pre_rank) {
    let rtnValue = "";
    if (pre_rank[item.name]) {
      if (pre_rank[item.name] - item.num > 0) {
        rtnValue = pre_rank[item.name] - item.num + "↑";
        if (pre_rank[item.name] - item.num > 5) {
          rtnValue = rtnValue + "↑";
        }
      } else if (pre_rank[item.name] - item.num < 0) {
        rtnValue = pre_rank[item.name] - item.num + "↓";
      } else {
        rtnValue = pre_rank[item.name];
      }
      return "(" + rtnValue +")";
    }
    return rtnValue;
  }

  function preranknum() {
    let rtnValue = "-";
    if (pre_rank[item.name]) {
      rtnValue = pre_rank[item.name];
    }
    return rtnValue;
  }

  function updownColor() {
    const red = "red-font-clr";
    const blue = "blue-font-clr";
    let rtnColor = "";
    if (pre_rank[item.name] - item.num > 0) {
      rtnColor = red;
    } else if (pre_rank[item.name] - item.num < 0) {
      rtnColor = blue;
    } else {
      rtnColor = "";
    }

    return rtnColor;
  }
</script>

<div class="flexbox">
  <div class="rank {i % 2 === 1 ? 'odd-bg-clr' : 'even-bg-clr'}">
    {item.num}
  </div>
  <div class="ticker {i % 2 === 1 ? 'odd-bg-clr' : 'even-bg-clr'}">
    {item.name}
  </div>
  <div class="times {i % 2 === 1 ? 'odd-bg-clr' : 'even-bg-clr'}">
    {item.count}
  </div>
  <div class="prerank {i % 2 === 1 ? 'odd-bg-clr' : 'even-bg-clr'}">
    <span>{preranknum(item, pre_rank)}</span>
    <span class={updownColor(item, pre_rank)}>{updown(item, pre_rank)}</span>
  </div>
  <div class="link {i % 2 === 1 ? 'odd-bg-clr' : 'even-bg-clr'}">
    <a href="https://finance.yahoo.com/quote/{item.name}" target="_blank"
      >yahoo</a
    >
  </div>
</div>

<style>
  a {
    color: #333;
  }
  .flexbox {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .rank {
    width: 60px;
    text-align: center;
  }

  .ticker {
    width: 100px;
    text-align: center;
  }

  .times {
    width: 100px;
    text-align: center;
  }

  .prerank {
    width: 140px;
    text-align: center;
  }

  .link {
    width: auto;
    text-align: center;
    font-size: 14px;
  }
  .odd-bg-clr {
    background-color: white;
  }
  .even-bg-clr {
    background-color: #e8e9ec;
  }

  .red-font-clr {
    color: #ff0000;
  }

  .blue-font-clr {
    color: #0000ff;
  }
</style>
